import '../styles/globals.scss';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import { FC, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { appWithTranslation } from 'next-i18next';
import '../styles/fixes.scss';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/search/lib/styles/index.css';
import Head from 'next/head';
import RuntimeConfigProvider from '../context/configContext';
import App from 'next/app';

type AppOwnProps = { runtimeConfig: NodeJS.ProcessEnv };

function MyApp({ Component, pageProps, runtimeConfig }: AppProps & AppOwnProps) {
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1800 * 1000
      }
    }
  }))

  return (
    <RuntimeConfigProvider runtimeConfig={runtimeConfig}>
      <QueryClientProvider client={queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <SnackbarProvider maxSnack={3}>
            <Head>
              <meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0, maximum-scale=1.0,user-scalable=no" />
            </Head>
            <Component {...pageProps} />
          </SnackbarProvider>
        </Hydrate>
      </QueryClientProvider>
    </RuntimeConfigProvider>
  )
};

MyApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
  const ctx = await App.getInitialProps(context);
  const runtimeConfig = process.env;

  return { ...ctx, runtimeConfig };
};

export default appWithTranslation(MyApp);

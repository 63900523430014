import { createContext, FC, ReactNode, useContext } from "react";

interface RuntimeConfigProviderProps {
  children: ReactNode;
  runtimeConfig: NodeJS.ProcessEnv;
}

const runtimeConfigContext = createContext<NodeJS.ProcessEnv>({} as NodeJS.ProcessEnv);
export const useConfig = () => useContext(runtimeConfigContext);

const RuntimeConfigProvider: FC<RuntimeConfigProviderProps> = ({ children, runtimeConfig }) => {
  return (
    <runtimeConfigContext.Provider value={runtimeConfig}>
      {children}
    </runtimeConfigContext.Provider>
  );
};

export default RuntimeConfigProvider;